@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300&family=Lexend+Peta:wght@200&display=swap');


.hr {
    border: 0;
    width: 97%;
    background-image: linear-gradient(to right, #05dbfd, #8b64e8,#de1cd9);
    height: 8px;
}
.header {
    padding: 6% 0 0 0px;
    background-image: linear-gradient(to right, #05dbfd, #8b64e8,#de1cd9);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    color: transparent;
    font-family: Jura;
    width: 15%;
    font-size: 300%;
}