@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300&family=Lexend+Peta:wght@200&display=swap');

.App {
  text-align: center;
  min-height: 200vh;
  /* background-image: linear-gradient(to right, #131434, #6061a8,indigo); */
}

/* .App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-image: linear-gradient(to right, #131434, #6061a8,indigo);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  color: transparent;
} */
.AppBar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 270%;
  font-family: Jura;
  padding: 1% 0 0 0;
  flex-direction: row;
}
.AppBarComponents{
  background-image: linear-gradient(to right, #05dbfd, #8b64e8,#de1cd9);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  color: transparent;
  padding: 0 30px 0 30px;
}
.AppBarComponents:hover{
  cursor: pointer;
}

.fantasylogo {
  max-width: 28%;
}

.logos {
  width: 42px;
  padding: 0 0 0 40px;    
  cursor: pointer;
}
.logocontainer {
  padding: 0 30px 0 0px;
}